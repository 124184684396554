<template>
    <div class="d-flex justify-content-evenly mt-1 w-100" :style="{ height: '7%', minHeight: '40px'}">
        <img class="h-100" src="/assets/gameScreenElements/settings-icon.png" alt="" />
        <img class="h-100" src="/assets/gameScreenElements/exit-icon.png" alt="" />
    </div>
</template>


<script>
export default {
    name: 'PowerupsUi',
}
</script>