import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import * as PIXI from 'pixi.js';

import { Config } from '../game/Config';
import preload from '../Loader';

export class MegamatchPixi {
  constructor(pixiUi) {
    this.pixiUi = pixiUi;

    this.configInstace = new Config(pixiUi);
  }

  async init() {
    gsap.registerPlugin(PixiPlugin);
    PixiPlugin.registerPIXI(PIXI);

    const canvasContainerElement = document.getElementById('canvas-container');
    // const canvaOoverlay = document.getElementById("canvas-overlay");

    const app = new PIXI.Application({
      // resizeTo: window,
    });

    // canvaOoverlay.style.width = `${this.configInstace.uiConstants.width}px`;
    // canvaOoverlay.style.height = `${this.configInstace.uiConstants.height}px`;

    app.view.width = this.configInstace.uiConstants.width;
    app.view.height = this.configInstace.uiConstants.height;
    app.view.setAttribute('class', 'main-canvas');

    canvasContainerElement.appendChild(app.view);

    await preload(PIXI.Assets);

    this.app = app;
    return this;
  }
}
