import { Config } from "./Config";
import { Tile } from "./Tile";
import { Tools } from "./Tools";

export class TileFactory {
  constructor(pixiUi) {
    this.pixiUi = pixiUi;

    this.configInstace = new Config(pixiUi);
  }
  
  generate(tileColor = undefined) {
    const color = tileColor
      ? tileColor
      : this.configInstace.tilesColors[
          Tools.randomNumber(0, this.configInstace.tilesColors.length - 1)
        ];
    return new Tile(color, this.pixiUi);
  }

  generateByColor(tileColor) {
    return new Tile(tileColor, this.pixiUi);
  }

  generateRandom() {
    const color =
    this.configInstace.tilesColors[Tools.randomNumber(0, this.configInstace.tilesColors.length - 1)];
    return new Tile(color, this.pixiUi);
  }
}
