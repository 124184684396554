/* eslint-disable no-unused-vars */
import * as PIXI from 'pixi.js';
import { Events, POWER_UPS, PlayerTurn, PlayerType, ScoreConfig } from '../enum/enum';
import { Board } from './Board';
import { CombinationManager } from './CombinationManager';
import { Config } from './Config';
import { EventEmitter } from './EventEmitter';
import { Player } from './Player';
import { animationPaths } from '../assetsPath';

export class Game {
  constructor(board = undefined, nextTiles, pixiUi) {
    this.pixiUi = pixiUi;
    this.currentTurnExipry = new Date();
    this.nextTiles = nextTiles;

    this.stateChanges = new EventEmitter(pixiUi);
    this.board = new Board(board, pixiUi);
    this.configInstace = new Config(pixiUi);

    this.swapTwoValidation = false;
    this.destroyFourValidation = false;
    this.potentialMovesValidation = false;
    this.showPotentialMove = false;

    this.tutorialGamePlay = false;

    if (this.pixiUi) {
      this.container = new PIXI.Container();
      // this.animationContainer = new PIXI.Container();
      this.container.addChild(this.board.container);
      // this.container.addChild(this.animationContainer);

      this.players = [new Player(PlayerType.REAL), new Player(PlayerType.BOT)];
      // this will be decided randomly
      this.turn = PlayerTurn.player1;

      this.board.container.on('tile-touch-start', this.onDragStart.bind(this));
      this.board.container.on('tile-touch-end', this.onDragEnd.bind(this));
      this.board.container.on('tile-touch-drag', this.onDragMove.bind(this));
    }

    this.combinationManager = new CombinationManager(this.board, pixiUi);

    this.removeStartMatches();

    this.gameCycle = 1;

    // set score to 0 after removeStartMatches execute so score is not impacted by initial matches
    if (this.pixiUi) {
      this.resetScoreAtStart();
    }
  }

  removeStartMatches() {
    let matches = this.combinationManager.getMatches();

    while (matches.length) {
      this.removeMatches(matches);

      const fields = this.board.fields.filter(field => field.tile === null);

      fields.forEach(field => {
        this.board.createTile(field);
      });
      matches = this.combinationManager.getMatches();
    }
  }

  onDragStart({ tile, event, swapByServer = false }) {
    if (this.disabled) return;
    this.selectedTile = tile;

    if (this.destroyFourValidation) {
      let matches = [];
      let fields = {
        tile1: {
          row: this.selectedTile.field.row,
          col: this.selectedTile.field.col,
        },
        tile2: null,
        tile3: null,
        tile4: null,
      };
      let rightTile = this.board.getField(
        this.selectedTile.field.row,
        this.selectedTile.field.col + 1,
      )?.tile;

      if (rightTile) {
        let rightBottomTile = this.board.getField(
          this.selectedTile.field.row + 1,
          this.selectedTile.field.col + 1,
        )?.tile;

        if (rightBottomTile) {
          let bottomTile = this.board.getField(
            this.selectedTile.field.row + 1,
            this.selectedTile.field.col,
          )?.tile;

          matches = [tile, rightTile, rightBottomTile, bottomTile];

          fields['tile2'] = {
            row: this.selectedTile.field.row + 1,
            col: this.selectedTile.field.col + 1,
          };

          fields['tile3'] = {
            row: this.selectedTile.field.row + 1,
            col: this.selectedTile.field.col,
          };

          fields['tile4'] = {
            row: this.selectedTile.field.row,
            col: this.selectedTile.field.col + 1,
          };
          const newMatchs = matches.map(t => {
            t.isBombPower = true;
            return t;
          });
          this.processMatches([newMatchs], swapByServer);
        } else {
          let rightTopTile = this.board.getField(
            this.selectedTile.field.row - 1,
            this.selectedTile.field.col + 1,
          )?.tile;

          let topTile = this.board.getField(
            this.selectedTile.field.row - 1,
            this.selectedTile.field.col,
          )?.tile;

          matches = [tile, rightTile, rightTopTile, topTile];

          fields['tile2'] = {
            row: this.selectedTile.field.row,
            col: this.selectedTile.field.col + 1,
          };

          fields['tile3'] = {
            row: this.selectedTile.field.row - 1,
            col: this.selectedTile.field.col + 1,
          };

          fields['tile4'] = {
            row: this.selectedTile.field.row - 1,
            col: this.selectedTile.field.col,
          };
          const newMatchs = matches.map(t => {
            t.isBombPower = true;
            return t;
          });
          this.processMatches([newMatchs], swapByServer);
        }
      } else {
        let leftTile = this.board.getField(
          this.selectedTile.field.row,
          this.selectedTile.field.col - 1,
        )?.tile;

        let leftBottomTile = this.board.getField(
          this.selectedTile.field.row + 1,
          this.selectedTile.field.col - 1,
        )?.tile;

        if (leftBottomTile) {
          let bottomTile = this.board.getField(
            this.selectedTile.field.row + 1,
            this.selectedTile.field.col,
          )?.tile;

          matches = [tile, leftTile, leftBottomTile, bottomTile];

          fields['tile2'] = {
            row: this.selectedTile.field.row + 1,
            col: this.selectedTile.field.col - 1,
          };

          fields['tile3'] = {
            row: this.selectedTile.field.row + 1,
            col: this.selectedTile.field.col,
          };

          fields['tile4'] = {
            row: this.selectedTile.field.row,
            col: this.selectedTile.field.col - 1,
          };
          const newMatchs = matches.map(t => {
            t.isBombPower = true;
            return t;
          });
          this.processMatches([newMatchs], swapByServer);
        } else {
          let leftTopTile = this.board.getField(
            this.selectedTile.field.row - 1,
            this.selectedTile.field.col - 1,
          )?.tile;

          let topTile = this.board.getField(
            this.selectedTile.field.row - 1,
            this.selectedTile.field.col,
          )?.tile;

          matches = [tile, leftTile, leftTopTile, topTile];

          fields['tile2'] = {
            row: this.selectedTile.field.row - 1,
            col: this.selectedTile.field.col - 1,
          };

          fields['tile3'] = {
            row: this.selectedTile.field.row - 1,
            col: this.selectedTile.field.col,
          };

          fields['tile4'] = {
            row: this.selectedTile.field.row,
            col: this.selectedTile.field.col - 1,
          };
          const newMatchs = matches.map(t => {
            t.isBombPower = true;
            return t;
          });
          this.processMatches([newMatchs], swapByServer);
        }
      }
      this.stateChanges.emit(Events.SWAP_MATCH, {
        ...fields,
        destroyFourValidation: this.destroyFourValidation,
      });
    } else {
      this.dragging = true;
      this.draggingData = event.data;
      this.dragStartCords = { x: tile.sprite.x, y: tile.sprite.y };
    }
  }

  destroyFour(tile1, tile2, tile3, tile4, swapByServer = false) {
    let t1 = this.board.getField(tile1.row, tile1.col).tile;
    let t2 = this.board.getField(tile2.row, tile2.col).tile;
    let t3 = this.board.getField(tile3.row, tile3.col).tile;
    let t4 = this.board.getField(tile4.row, tile4.col).tile;
    let matches = [t1, t2, t3, t4];
    // this.processMatches([matches]);
    const newMatchs = matches.map(t => {
      t.isBombPower = true;
      return t;
    });
    this.processMatches([newMatchs], swapByServer);
  }

  onDragEnd({ tile, event }) {
    if (this.disabled) return;
    if (!this.selectedTile) return;

    if (tile && this.swapTwoValidation) {
      this.selectedTile.swapTwoAnimation();
      tile.swapTwoAnimation();
      this.swap(this.selectedTile, tile);
    } else {
      let neighbourTile = null;
      const mousePosition = event.data.getLocalPosition(this.selectedTile.sprite.parent);

      if (
        Math.abs(this.dragStartCords.x - mousePosition.x) >=
        this.configInstace.board.sprightWidth / 2
      ) {
        // x direction swipe
        if (this.dragStartCords.x - mousePosition.x < 0) {
          // right swipe
          neighbourTile = this.board.getField(
            this.selectedTile.field.row,
            this.selectedTile.field.col + 1,
          )?.tile;
        } else {
          // left swipe
          neighbourTile = this.board.getField(
            this.selectedTile.field.row,
            this.selectedTile.field.col - 1,
          )?.tile;
        }
      } else if (
        Math.abs(this.dragStartCords.y - mousePosition.y) >=
        this.configInstace.board.sprightWidth / 2
      ) {
        // y direction swipe
        if (this.dragStartCords.y - mousePosition.y < 0) {
          // bottom swipe
          neighbourTile = this.board.getField(
            this.selectedTile.field.row + 1,
            this.selectedTile.field.col,
          )?.tile;
        } else {
          // top swipe
          neighbourTile = this.board.getField(
            this.selectedTile.field.row - 1,
            this.selectedTile.field.col,
          )?.tile;
        }
      }

      if (neighbourTile) this.swap(this.selectedTile, neighbourTile);
    }
    this.dragging = false;
    this.selectedTile = null;
  }

  onDragMove(tile) {
    if (this.disabled) return;
  }

  swap(selectedTile, tile, reverse, swapByServer = false) {
    if (
      new Date(this.currentTurnExipry).getTime() - new Date().getTime() < 500 &&
      !swapByServer &&
      !this.tutorialGamePlay &&
      !reverse
    ) {
      console.log('last 500 miliseconds not accepted!');
      return;
    }
    this.disabled = true;

    if (this.pixiUi) {
      selectedTile.sprite.zIndex = 2;
    }

    selectedTile.moveTo(tile.field.position, 0.2);

    this.clearSelection();

    tile.moveTo(selectedTile.field.position, 0.2).then(() => {
      this.board.swap(selectedTile, tile);

      if (!reverse) {
        const matches = this.combinationManager.getMatches();
        if (matches.length || this.swapTwoValidation) {
          if (this.pixiUi) {
            this.stateChanges.emit(Events.SWAP_MATCH, {
              sourceTile: {
                row: selectedTile.field.row,
                col: selectedTile.field.col,
              },
              destTile: {
                row: tile.field.row,
                col: tile.field.col,
              },
              swapTwoValidation: this.swapTwoValidation,
              showPotentialMove: this.showPotentialMove,
              swapByServer,
              matchesLength: matches.length,
            });
            if (this.swapTwoValidation && matches.length === 0) {
              this.disabled = false;
            }
            this.swapTwoValidation = false;
          }
          if (matches.length) this.processMatches(matches, swapByServer);
        } else {
          if (!this.swapTwoValidation) this.swap(tile, selectedTile, true, swapByServer);
        }
      } else {
        this.disabled = false;
      }
    });
  }

  removeMatches(matches) {
    matches.forEach(match => {
      match.forEach(tile => {
        if (tile.sprite !== null) {
          this.addScore(tile.color);
        }
        tile.remove();
      });
    });
  }

  processMatches(matches, swapByServer = false) {
    this.removeMatches(matches);

    setTimeout(() => {
      this.processFallDown()
        .then(() => this.addTiles())
        .then(() => this.onFallDownOver(swapByServer));
      // .then(() => this.updateScore());
    }, 500);
  }

  addScore(color) {
    if (this.pixiUi) {
      this.players[this.turn].score += ScoreConfig[color];
    } else {
      this.stateChanges.emit(Events.CHANGES, color);
    }
  }

  onFallDownOver(swapByServer = false) {
    const matches = this.combinationManager.getMatches();
    if (matches.length) {
      this.processMatches(matches, swapByServer);
    } else {
      if (this.pixiUi) {
        this.turn =
          this.turn == PlayerTurn.player1 ? PlayerTurn.player2 : PlayerTurn.player1;
      }

      this.gameCycle += 1;
      this.disabled = false;

      if (this.pixiUi) {
        this.stateChanges.emit(Events.CHANGES, swapByServer);
      }
      // this.board.isPointerUpTriggered = false;
    }
  }

  get2dBoard() {
    let data = [];
    for (let i = 0; i < this.configInstace.board.rows; i++) {
      let row = [];
      for (let j = 0; j < this.configInstace.board.cols; j++) {
        row.push(
          this.configInstace.tilesColors.indexOf(this.board.getField(i, j).tile.color),
        );
      }
      data.push(row);
    }
    return data;
  }

  addTiles() {
    return new Promise(resolve => {
      const fields = this.board.fields.filter(field => field.tile === null);
      let total = fields.length;
      let completed = 0;

      fields.forEach(field => {
        const tileIndex = this.nextTiles.pop();

        const tile = this.nextTiles
          ? this.board.createTile(field, this.configInstace.tilesColors[tileIndex])
          : this.board.createTile(field);

        tile.sprite.width =
          (this.configInstace.uiConstants.width / this.configInstace.board.cols) * 0.9;
        tile.sprite.height =
          (this.configInstace.uiConstants.height / this.configInstace.board.cols) * 0.9;

        tile.sprite.y = -500;
        const delay = (Math.random() * 2) / 10 + 0.3 / (field.row + 1);
        tile.fallDownTo(field.position, delay).then(() => {
          ++completed;
          if (completed >= total) {
            resolve();
          }
        });
      });
    });
  }

  processFallDown() {
    return new Promise(resolve => {
      let completed = 0;
      let started = 0;

      for (let row = this.board.rows - 1; row >= 0; row--) {
        for (let col = this.board.cols - 1; col >= 0; col--) {
          const field = this.board.getField(row, col);

          if (!field.tile) {
            ++started;
            this.fallDownTo(field).then(() => {
              ++completed;
              if (completed >= started) {
                resolve();
              }
            });
          }
        }
      }
    });
  }

  fallDownTo(emptyField) {
    for (let row = emptyField.row - 1; row >= 0; row--) {
      let fallingField = this.board.getField(row, emptyField.col);

      if (fallingField.tile) {
        const fallingTile = fallingField.tile;
        fallingTile.field = emptyField;
        emptyField.tile = fallingTile;
        fallingField.tile = null;
        return fallingTile.fallDownTo(emptyField.position);
      }
    }

    return Promise.resolve();
  }

  clearSelection() {
    if (this.selectedTile) {
      if (this.selectedTile.field) {
        this.selectedTile.field.unselect();
      }
      this.selectedTile = null;
    }
  }

  selectTile(tile) {
    this.selectedTile = tile;
    this.selectedTile.field.select();
  }

  resetScoreAtStart() {
    this.players[0].score = 0;
    this.players[1].score = 0;
  }

  // botPlay
  potentialMoves() {
    let totalRows = this.configInstace.board.rows;
    let totalColumns = this.configInstace.board.cols;

    let swapPairs = {};

    for (let i = 0; i < totalRows; i++) {
      for (let j = 0; j < totalColumns; j++) {
        let tile = this.board.getField(i, j)?.tile;

        let tileSwappedInRightSwipe = this.board.getField(i, j + 1)?.tile;

        if (tileSwappedInRightSwipe) {
          let result = this.findMatchesAndScore(
            this.board,
            tile.color,
            tileSwappedInRightSwipe.field.row,
            tileSwappedInRightSwipe.field.col,
            totalRows,
            totalColumns,
            'rightSwipe',
          );
          if (result.swapAllowed) {
            let color = result.color;
            let score = ScoreConfig[color] * result.numberOfGems;
            swapPairs[score] = {
              tile1: tile,
              tile2: tileSwappedInRightSwipe,
            };
          }
        }

        let tileSwappedInLeftSwipe = this.board.getField(i, j - 1)?.tile;
        if (tileSwappedInLeftSwipe) {
          let result = this.findMatchesAndScore(
            this.board,
            tile.color,
            tileSwappedInLeftSwipe.field.row,
            tileSwappedInLeftSwipe.field.col,
            totalRows,
            totalColumns,
            'leftSwipe',
          );
          if (result.swapAllowed) {
            let color = result.color;
            let score = ScoreConfig[color] * result.numberOfGems;
            swapPairs[score] = {
              tile1: tile,
              tile2: tileSwappedInLeftSwipe,
            };
          }
        }

        let tileSwappedInBottomSwipe = this.board.getField(i + 1, j)?.tile;
        if (tileSwappedInBottomSwipe) {
          let result = this.findMatchesAndScore(
            this.board,
            tile.color,
            tileSwappedInBottomSwipe.field.row,
            tileSwappedInBottomSwipe.field.col,
            totalRows,
            totalColumns,
            'bottomSwipe',
          );
          if (result.swapAllowed) {
            let color = result.color;
            let score = ScoreConfig[color] * result.numberOfGems;
            swapPairs[score] = {
              tile1: tile,
              tile2: tileSwappedInBottomSwipe,
            };
          }
        }

        let tileSwappedInTopSwipe = this.board.getField(i - 1, j)?.tile;
        if (tileSwappedInTopSwipe) {
          let result = this.findMatchesAndScore(
            this.board,
            tile.color,
            tileSwappedInTopSwipe.field.row,
            tileSwappedInTopSwipe.field.col,
            totalRows,
            totalColumns,
            'topSwipe',
          );
          if (result.swapAllowed) {
            let color = result.color;
            let score = ScoreConfig[color] * result.numberOfGems;
            swapPairs[score] = {
              tile1: tile,
              tile2: tileSwappedInTopSwipe,
            };
          }
        }
      }
    }

    const sc = Object.keys(swapPairs);
    const scores = sc.map(ele => +ele);

    scores.sort((a, b) => a - b);

    if (this.turn == PlayerTurn.player1) {
      return swapPairs[scores[scores.length - 1]];
    }
    this.swap(swapPairs[scores[0]].tile1, swapPairs[scores[0]].tile2);
  }

  findMatchesAndScore(
    board,
    refCheckColor,
    startRow,
    startCol,
    totalRows,
    totalColumns,
    direction,
  ) {
    let horizontalMatches = [];
    let verticalMatches = [];
    let totalMatches = [];
    let result = {
      numberOfGems: 0,
      color: refCheckColor,
      swapAllowed: false,
    };
    let tempCol = startCol;
    tempCol++;
    while (tempCol < totalColumns && direction !== 'leftSwipe') {
      let tempTile = board.getField(startRow, tempCol)?.tile;
      let tempTileColor = tempTile?.color;
      if (tempTile && tempTileColor && refCheckColor === tempTileColor) {
        horizontalMatches.push(tempTile);
        tempCol++;
      } else {
        break;
      }
    }

    tempCol = startCol;
    tempCol--;
    while (tempCol >= 0 && direction !== 'rightSwipe') {
      let tempTile = board.getField(startRow, tempCol)?.tile;
      let tempTileColor = tempTile?.color;
      if (tempTile && tempTileColor && refCheckColor === tempTileColor) {
        horizontalMatches.push(tempTile);
        tempCol--;
      } else {
        break;
      }
    }

    let tempRow = startRow;
    tempRow++;
    while (tempRow < totalRows && direction !== 'topSwipe') {
      let tempTile = board.getField(tempRow, startCol)?.tile;
      let tempTileColor = tempTile?.color;
      if (tempTile && tempTileColor && refCheckColor === tempTileColor) {
        verticalMatches.push(tempTile);
        tempRow++;
      } else {
        break;
      }
    }

    tempRow = startRow;
    tempRow--;
    while (tempRow >= 0 && direction !== 'bottomSwipe') {
      let tempTile = board.getField(tempRow, startCol)?.tile;
      let tempTileColor = tempTile?.color;
      if (tempTile && tempTileColor && refCheckColor === tempTileColor) {
        verticalMatches.push(tempTile);
        tempRow--;
      } else {
        break;
      }
    }

    if (horizontalMatches.length >= 2 && verticalMatches.length < 2) {
      totalMatches = [...horizontalMatches, true];
    } else if (verticalMatches.length >= 2 && horizontalMatches.length < 2) {
      totalMatches = [...verticalMatches, true];
    } else if (horizontalMatches.length >= 2 && verticalMatches.length >= 2) {
      totalMatches = [...horizontalMatches, ...verticalMatches, true];
    } else {
      totalMatches = [true];
    }

    if (totalMatches.length >= 3) {
      result.numberOfGems = totalMatches.length;
      result.swapAllowed = true;
      return result;
    } else {
      return result;
    }
  }

  updateBoard(board, nextTiles) {
    this.container.getChildByName('board').destroy();
    this.board = new Board(board, this.pixiUi);
    this.nextTiles = nextTiles;

    this.board.container.on('tile-touch-start', this.onDragStart.bind(this));
    this.board.container.on('tile-touch-end', this.onDragEnd.bind(this));
    this.board.container.on('tile-touch-drag', this.onDragMove.bind(this));
    this.combinationManager = new CombinationManager(this.board, this.pixiUi);
    this.container.addChild(this.board.container);

    /* adding and updating same animation sprite into parent container,
    coz animation isn't visible after updating board */
    const existingAnimation = this.container.getChildByName('animation');
    if (existingAnimation) {
      existingAnimation.removeFromParent();
      this.container.addChild(existingAnimation);
    }
  }

  async playPowerupAnimation(powerupName, onComplete = undefined) {
    if (!this.container) return;

    let animationPath = '',
      animationKey = '';

    switch (powerupName) {
      case POWER_UPS.FREEZE: {
        animationPath = animationPaths[5];
        animationKey = 'freeze';
        break;
      }
      case POWER_UPS.DOUBLE_POINTS: {
        animationPath = animationPaths[8];
        animationKey = '2x-points';
        break;
      }
      case POWER_UPS.RESHUFFLE: {
        animationPath = animationPaths[6];
        animationKey = 'reshuffle';
        break;
      }
      case POWER_UPS.POTENTIAL_MOVES: {
        animationPath = animationPaths[7];
        animationKey = 'search';
        break;
      }
      default:
        return;
    }

    /**
     * @type PIXI.Spritesheet
     */
    let spriteSheet = PIXI.Cache.get(animationPath);
    if (!spriteSheet) {
      spriteSheet = await PIXI.Assets.load(animationPath);
    }

    /**
     * @type PIXI.AnimatedSprite
     */
    const animation = PIXI.AnimatedSprite.fromFrames(
      spriteSheet.data.animations[animationKey],
    );

    animation.name = 'animation';
    animation.position.x = 0;
    animation.position.y = 0;
    animation.width = this.configInstace.uiConstants.width;
    animation.height = this.configInstace.uiConstants.width;
    animation.animationSpeed = 1 / 3;
    animation.loop = false;

    animation.play();

    this.container.addChild(animation);

    animation.onComplete = () => {
      animation.gotoAndStop(0);
      animation.destroy();
      if (onComplete) onComplete();
    };
  }

  resetValidations() {
    this.swapTwoValidation = false;
    this.destroyFourValidation = false;
    this.potentialMovesValidation = false;
    this.showPotentialMove = false;
    this.disabled = false;
  }
}
