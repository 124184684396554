<template>
  <div v-bind:style="backgroundImage" className="container h-25 mt-2">
    <!-- profile div  -->
    <div className="h-50 row">
      <div className="col h-100">
        <div className="align-items-center d-flex h-50 w-100">
          <img
            :src="playerIconBlue[room[0]?.avatar] || playerIconBlue['armadillo']"
            alt=""
            className="h-100 w-50"
          />
          <div className="d-flex flex-column text-center w-100">
            <span>{{ room[0]?.userName || 'you' }}</span>
            <!-- <span style="font-size: 0.6rem">{{ room[0]?.phoneNumber }}</span> -->
          </div>
        </div>
        <div class="h-50 mt-2">
          <ProgressBar
            :progressVal="progressVal(room[0]?.turnNo)"
            :labelVal="`${get(room, '0.turnNo', 0)}/8`"
            :height="2.8"
            :color="'bg-info'"
          />
        </div>
      </div>

      <div className="align-items-center col-4 d-flex h-75 justify-content-center p-0">
        <img className="h-100 w-100" :src="scoreUiElements['logo']" alt="" />
      </div>
      <div className="col h-100">
        <div className="align-items-center d-flex h-50 w-100">
          <div className="d-flex flex-column text-center w-100">
            <span>{{ room[1]?.userName || 'enemy' }}</span>
            <!-- <span style="font-size: 0.6rem">{{ room[1]?.phoneNumber }}</span> -->
          </div>
          <img
            :src="playerIconRed[room[1]?.avatar] || playerIconRed['armadillo']"
            alt=""
            className="h-100 w-50"
          />
        </div>
        <div class="h-50 mt-2">
          <ProgressBar
            :progressVal="progressVal(room[1]?.turnNo)"
            :direction="'flex-row-reverse'"
            :labelVal="`${get(room, '1.turnNo', 0)}/8`"
            :height="2.8"
            :color="'bg-danger'"
          />
        </div>
      </div>
    </div>

    <!-- score div  -->
    <div className="h-50 row justify-content-center" :style="{ minHeight: '90px' }">
      <div className="col-4 h-75">
        <div className="h-100 position-relative">
          <div
            id="score-box-blue"
            className="blue-player-score-div h-75 position-absolute pt-2 score-div px-3 text-white w-75"
          >
            <!-- <ScoreAnimation /> -->

            <number
              id="score-one"
              class="score-digit"
              ref="number1"
              :from="0"
              :to="room[0]?.score"
              :format="theFormat"
              :duration="1"
              :delay="1"
              easing="Bounce.easeInOut"
            />
            <!-- <span id="score-one" class="score-digit">{{
              room[0]?.score || 0
            }}</span> -->
          </div>

          <div
            id="score-box-red"
            className="h-75 position-absolute pt-3 red-player-score-div score-div text-center text-white w-75"
          >
            <!-- <span id="score-two" class="score-digit">{{
              room[1]?.score || 0
            }}</span> -->
            <number
              id="score-two"
              class="score-digit"
              ref="number1"
              :from="0"
              :to="room[1]?.score"
              :format="theFormat"
              :duration="1"
              :delay="1"
              easing="Bounce.easeInOut"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';

  defineProps({
    room: {
      type: Array,
    },
    turn: {
      type: String,
    },
  });
</script>

<script>
  import ProgressBar from '../ProgressBar';
  import { get } from 'lodash';
  import {
    PlayerIconBlue,
    PlayerIconRed,
    staticImages,
  } from '../../constants/imageUrlPath';

  export default {
    name: 'ScoreUi',
    data() {
      return {
        playerIconBlue: PlayerIconBlue,
        playerIconRed: PlayerIconRed,
        scoreUiElements: {
          logo: staticImages.logo,
        },
      };
    },
    components: {
      ProgressBar,
    },
    methods: {
      progressVal(remainingTurn) {
        if (remainingTurn == undefined) remainingTurn = 0;
        const totalTurn = 8;
        let progress = (remainingTurn / totalTurn) * 100;
        return progress;
      },
      theFormat(number) {
        return number.toFixed();
      },
    },
    computed: {
      backgroundImage() {
        return {
          '--blue-player-score-div-background': `url(${staticImages['bluePlayerTurn']})`,
          '--red-player-score-div-background': `url(${staticImages['redPLayerTurn']})`,
        };
      },
    },
  };
</script>

<style lang="css">
  @import './index.css';
</style>
