export class Config {
  constructor(pixiUi) {
    this.pixiUi = pixiUi;

    this.tilesColors = [
      "blue-gem",
      "green-gem",
      "gold-gem",
      "red-gem",
      "purple-gem",
    ];

    this.board = {
      rows: 7,
      cols: 7,
    };

    this.combinationRules = [
      [
        { col: 1, row: 0 },
        { col: 2, row: 0 },
      ],
      [
        { col: 0, row: 1 },
        { col: 0, row: 2 },
      ],
    ];

    if (this.pixiUi) {
      this.uiConstants = this.uiCalculations();
      this.board.sprightWidth = this.uiConstants.width / 7;
    }
  }

  uiCalculations() {
    let screenOrientation =
      window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    if (window.innerWidth > 480 && window.innerWidth < 1024) {
      screenOrientation = "tablet";
    }
    let containerX, width, height;

    if (screenOrientation === "portrait") {
      containerX = window.innerWidth * 0.93;
      width = window.innerWidth * 0.93;
      height = width;
    } else {
      if(window.innerHeight > 1124){
        let temp = 440 / window.innerHeight;
        containerX = window.innerHeight * temp;  
      }
      else {
        containerX = window.innerHeight * 0.43;
      }
      height = window.innerHeight * 0.43;
      width = height;
    }

    return {
      containerX,
      width: width > 480 ? 440 : width,
      height: width > 480 ? 440 : height,
    };
  }
}
