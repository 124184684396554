import { gsap } from 'gsap';
import * as PIXI from 'pixi.js';
import { Config } from './Config';
import { animationPaths } from '../assetsPath';

export class Tile {
  constructor(color, pixiUi) {
    this.pixiUi = pixiUi;

    this.color = color;

    this.configInstace = new Config(pixiUi);

    this.isBombPower = false;

    this.baseURL = window.location.origin;

    if (this.pixiUi) {
      this.sprite = PIXI.Sprite.from(
        PIXI.Cache.get(`${this.baseURL}/assets/images/${color}.png`),
      );

      this.sprite.width =
        (this.configInstace.uiConstants.width / this.configInstace.board.cols) * 0.9;
      this.sprite.height =
        (this.configInstace.uiConstants.height / this.configInstace.board.cols) * 0.9;

      this.sprite.anchor.set(0.5);

      /** @type PIXI.AnimatedSprite */
      this.animation = PIXI.AnimatedSprite.fromFrames(
        /* keyname should be same as filename */
        PIXI.Cache.get(animationPaths[0]).data.animations[
          'SingleExplosion01-PNG-Sequence/SingleExplosion01'
        ],
      );
      this.animation.onComplete = () => {
        this.animation.gotoAndStop(0);
        this.animation.destroy();
        this.animation = null;
      };
      this.animation.anchor.set(0.5);
      this.animation.loop = false;
      this.animation.animationSpeed = 1 / 3;
      this.animation.width = this.sprite.width;
      this.animation.height = this.sprite.height;

      this.bombAnimation = PIXI.AnimatedSprite.fromFrames(
        PIXI.Cache.get(animationPaths[10]).data.animations['bomb'],
      );

      this.bombAnimation.onComplete = () => {
        this.bombAnimation.gotoAndStop(0);
        this.bombAnimation.destroy();
        this.bombAnimation = null;
      };
      this.bombAnimation.anchor.set(0.5);
      this.bombAnimation.loop = false;
      this.bombAnimation.animationSpeed = 1 / 2;
      this.bombAnimation.width = this.sprite.width;
      this.bombAnimation.height = this.sprite.height;

      this.swapAnimation = PIXI.AnimatedSprite.fromFrames(
        PIXI.Cache.get(animationPaths[9]).data.animations['swap'],
      );
      this.swapAnimation.onComplete = () => {
        this.swapAnimation.gotoAndStop(0);
        this.swapAnimation.destroy();
        this.swapAnimation = null;
      };
      this.swapAnimation.anchor.set(0.5);
      this.swapAnimation.loop = false;
      this.swapAnimation.animationSpeed = 1 / 3;
      this.swapAnimation.width = this.sprite.width;
      this.swapAnimation.height = this.sprite.height;
    }
  }

  setPosition(position) {
    if (this.pixiUi) {
      this.sprite.x = position.x;
      this.sprite.y = position.y;
      this.bombAnimation.x = position.x;
      this.bombAnimation.y = position.y;
      this.swapAnimation.x = position.x;
      this.swapAnimation.y = position.y;
      this.animation.x = position.x;
      this.animation.y = position.y;
    } else {
      this.x = position.x;
      this.y = position.y;
    }
  }

  moveTo(position, duration, delay, ease) {
    return new Promise(resolve => {
      if (!this.pixiUi) {
        this.x = position.x;
        this.y = position.y;
        resolve();
      } else {
        this.animation.x = position.x;
        this.animation.y = position.y;
        if (this.bombAnimation && this.swapAnimation) {
          this.bombAnimation.x = position.x;
          this.bombAnimation.y = position.y;
          this.swapAnimation.x = position.x;
          this.swapAnimation.y = position.y;
        }
        gsap.to(this.sprite, {
          duration,
          delay,
          ease,
          pixi: {
            x: position.x,
            y: position.y,
          },
          onComplete: () => {
            resolve();
          },
        });
      }
    });
  }
  isNeighbour(tile) {
    return (
      Math.abs(this.field.row - tile.field.row) +
        Math.abs(this.field.col - tile.field.col) ===
      1
    );
  }

  remove() {
    if (this.pixiUi) {
      if (!this.sprite) {
        return;
      }
      if (this.isBombPower) {
        this.bombAnimation.play();
      } else {
        this.animation.play();
      }
      this.sprite.destroy();
      this.sprite = null;
    }

    if (this.field) {
      this.field.tile = null;
      this.field = null;
    }
  }

  fallDownTo(position, delay) {
    return this.moveTo(position, 0.5, delay, 'bounce.out');
  }

  swapTwoAnimation() {
    if (this.swapAnimation) {
      this.swapAnimation.play();
    }
  }
}
