import * as PIXI from 'pixi.js';
import { Config } from './Config';

export class Field {
  constructor(row, col, pixiUi) {
    this.pixiUi = pixiUi;

    this.configInstace = new Config(pixiUi);

    this.row = row;
    this.col = col;

    this.baseURL = window.location.origin;

    if (!this.pixiUi) {
      this.width = 362 / this.configInstace.board.cols;
      this.height = 362 / this.configInstace.board.cols;
    }

    if (this.pixiUi) {
      if ((row + col) % 2 === 0) {
        this.sprite = PIXI.Sprite.from(
          PIXI.Cache.get(`${this.baseURL}/assets/images/field1.jpg`),
        );
      } else {
        this.sprite = PIXI.Sprite.from(
          PIXI.Cache.get(`${this.baseURL}/assets/images/field2.jpg`),
        );
      }

      this.sprite.width =
        this.configInstace.uiConstants.width / this.configInstace.board.cols;
      this.sprite.height =
        this.configInstace.uiConstants.height / this.configInstace.board.cols;

      this.sprite.x = this.position.x;
      this.sprite.y = this.position.y;
      this.sprite.anchor.set(0.5);

      this.selected = PIXI.Sprite.from(
        PIXI.Cache.get(`${this.baseURL}/assets/images/field-selected.jpg`),
      );
      this.sprite.addChild(this.selected);

      this.selected.visible = false;
      this.selected.anchor.set(0.5);
    }
  }

  unselect() {
    this.selected.visible = false;
  }

  select() {
    this.selected.visible = true;
  }

  get position() {
    return {
      x: this.pixiUi ? this.col * this.sprite.width : this.col * this.width,
      y: this.pixiUi ? this.row * this.sprite.height : this.row * this.height,
    };
  }

  setTile(tile) {
    this.tile = tile;
    tile.field = this;
    tile.setPosition(this.position);
  }
}
