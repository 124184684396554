<!-- how to use it -->
<!-- 
  <PreloadStaticImages @images-loaded="yourOnImageLoadHandler" />
-->

<template>
  <img
    v-for="(image, index) in images"
    :key="index"
    :src="image"
    style="display: none"
    @load="onImageLoad"
  />
</template>

<script>
  import { staticImagePaths } from '../../assetsPath';

  export default {
    name: 'PreloadStaticImages',
    data() {
      return {
        images: staticImagePaths,
        loadedImages: 0,
      };
    },
    methods: {
      onImageLoad() {
        this.loadedImages++;
        this.$emit('loaded-images', this.loadedImages, this.images.length);
        if (this.loadedImages === this.images.length) {
          this.$emit('images-loaded');
        }
      },
    },
  };
</script>
