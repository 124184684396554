export const leaderboardData = [
  { name: 'Jazza', entries: 12, prize: '2GB' },
  { name: 'King-KyLe', entries: 3, prize: '1GB' },
  { name: 'Timmouthy', entries: 1, prize: '1GB' },
  { name: 'Bruce', entries: 32, prize: '1GB' },
  { name: 'Braddo', entries: 19, prize: '1GB' },
  { name: 'Ethanator', entries: 2, prize: '1GB' },
  { name: 'Knoxie', entries: 8, prize: '1GB' },
  { name: 'Chel SFan', entries: 5, prize: '1GB' },
  { name: 'Rubyrubs', entries: 10, prize: '1GB' },
  { name: 'Ajay88', entries: 26, prize: '1GB' },
];

export const topupFundsData = [
  { id: 1, name: '10 coins .......... R10 Airtime' },
  { id: 2, name: '20 coins ........ R15 Airtime' },
  { id: 3, name: '50 coins ........ R25 Airtime' },
  { id: 4, name: '100 coins ...... R35 Airtime' },
];
