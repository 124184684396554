<template>
  <div
    class="animation"
    v-show="
      this.doublePointsAnimation ||
      this.searchAnimation ||
      this.freezeAnimation ||
      this.reshuffleAnimation
    "
  >
    <div id="animation"></div>
  </div>
</template>

<script>
  import * as PIXI from 'pixi.js';
  import { animationPaths } from '../../assetsPath';
  import { Config } from '../../game/Config';

  let configInstance,
    app,
    animationReshuffle,
    animationFreeze,
    animation2x,
    animationSearch;

  export default {
    name: 'PowerUpAnimation',
    props: {
      doublePointsAnimation: Boolean,
      searchAnimation: Boolean,
      freezeAnimation: Boolean,
      reshuffleAnimation: Boolean,
      onAnimation2xComplete: Function,
      onAnimationSearchComplete: Function,
      onAnimationFreezeComplete: Function,
      onAnimationReshuffleComplete: Function,
    },

    watch: {
      doublePointsAnimation: async function (val) {
        if (val) {
          animation2x.play();
          animation2x.onComplete = () => {
            this.onAnimation2xComplete();
            animation2x.gotoAndStop(0);
          };
        }
      },

      searchAnimation: async function (val) {
        if (val) {
          animationSearch.play();
          animationSearch.onComplete = () => {
            this.onAnimationSearchComplete();
            animationSearch.gotoAndStop(0);
          };
        }
      },

      freezeAnimation: async function (val) {
        if (val) {
          animationFreeze.play();
          animationFreeze.onComplete = () => {
            this.onAnimationFreezeComplete();
            animationFreeze.gotoAndStop(0);
          };
        }
      },

      reshuffleAnimation: async function (val) {
        if (val) {
          animationReshuffle.play();
          animationReshuffle.onComplete = () => {
            this.onAnimationReshuffleComplete();
            animationReshuffle.gotoAndStop(0);
          };
        }
      },
    },

    async mounted() {
      app = new PIXI.Application({ backgroundAlpha: 0 });
      configInstance = new Config(true);
      (app.view.width = configInstance.uiConstants.width * 0.75),
        (app.view.height = configInstance.uiConstants.height * 0.95);
      document.getElementById(`animation`).appendChild(app.view);

      let spriteSheetFor2x = PIXI.Cache.get(animationPaths[8]);
      if (!spriteSheetFor2x) {
        spriteSheetFor2x = await PIXI.Assets.load(animationPaths[8]);
      }
      if (spriteSheetFor2x?.data?.animations) {
        animation2x = PIXI.AnimatedSprite.fromFrames(
          spriteSheetFor2x.data.animations['2x-points'],
        );
      }
      animation2x.position.x = 0;
      animation2x.position.y = 10;
      animation2x.loop = false;
      animation2x.width = configInstance.uiConstants.width * 0.75;
      animation2x.height = configInstance.uiConstants.width * 0.9;

      animation2x.animationSpeed = 1 / 3;
      if (app.stage) {
        app.stage.addChild(animation2x);
      }

      let spriteSheetForSearch = PIXI.Cache.get(animationPaths[7]);
      if (!spriteSheetForSearch) {
        spriteSheetForSearch = await PIXI.Assets.load(animationPaths[7]);
      }
      if (spriteSheetForSearch?.data?.animations) {
        animationSearch = PIXI.AnimatedSprite.fromFrames(
          spriteSheetForSearch.data.animations['search'],
        );
      }
      animationSearch.position.x = 0;
      animationSearch.position.y = 10;
      animationSearch.loop = false;
      animationSearch.width = configInstance.uiConstants.width * 0.75;
      animationSearch.height = configInstance.uiConstants.width * 0.9;

      animationSearch.animationSpeed = 1 / 3;
      if (app.stage) {
        app.stage.addChild(animationSearch);
      }

      let spriteSheetForFreeze = PIXI.Cache.get(animationPaths[5]);
      if (!spriteSheetForFreeze) {
        spriteSheetForFreeze = await PIXI.Assets.load(animationPaths[5]);
      }
      if (spriteSheetForFreeze?.data?.animations) {
        animationFreeze = PIXI.AnimatedSprite.fromFrames(
          spriteSheetForFreeze.data.animations['freeze'],
        );
      }
      animationFreeze.position.x = 0;
      animationFreeze.position.y = 10;
      animationFreeze.loop = false;
      animationFreeze.width = configInstance.uiConstants.width * 0.75;
      animationFreeze.height = configInstance.uiConstants.width * 0.9;

      animationFreeze.animationSpeed = 1 / 3;
      if (app.stage) {
        app.stage.addChild(animationFreeze);
      }

      let spriteSheetForReshuffle = PIXI.Cache.get(animationPaths[6]);
      if (!spriteSheetForReshuffle) {
        spriteSheetForReshuffle = await PIXI.Assets.load(animationPaths[6]);
      }
      if (spriteSheetForReshuffle?.data?.animations) {
        animationReshuffle = PIXI.AnimatedSprite.fromFrames(
          spriteSheetForReshuffle.data.animations['reshuffle'],
        );
      }
      animationReshuffle.position.x = 0;
      animationReshuffle.position.y = 10;
      animationReshuffle.loop = false;
      animationReshuffle.width = configInstance.uiConstants.width * 0.75;
      animationReshuffle.height = configInstance.uiConstants.width * 0.9;

      animationReshuffle.animationSpeed = 1 / 3;
      if (app.stage) {
        app.stage.addChild(animationReshuffle);
      }
    },

    unmounted() {
      if (app) {
        console.log('app', app);
        app.destroy();
        app = null;
        animation2x.destroy();
        animationFreeze.destroy();
        animationReshuffle.destroy();
        animationSearch.destroy();
        animation2x = null;
        animationFreeze = null;
        animationReshuffle = null;
        animationSearch = null;
      }
    },
  };
</script>
<style lang="css">
  @import './index.css';
</style>
