import * as PIXI from 'pixi.js';
import { Config } from './Config';
import { Field } from './Field';
import { TileFactory } from './TileFactory';

export class Board {
  constructor(board = undefined, pixiUi) {
    this.pixiUi = pixiUi;

    this.tileFactoryInstance = new TileFactory(pixiUi);
    this.configInstace = new Config(pixiUi);

    if (this.pixiUi) {
      this.container = new PIXI.Container();
      this.container.name = 'board';
    }

    this.fields = [];
    this.rows = this.configInstace.board.rows;
    this.cols = this.configInstace.board.cols;
    this.create(board);

    if (this.pixiUi) {
      this.ajustPosition();
    }
  }

  create(board) {
    this.createFields();
    this.createTiles(board);
  }

  createTiles(board) {
    this.fields.forEach(field => {
      if (board) {
        const colorName = this.configInstace.tilesColors[board[field.row][field.col]];
        this.createTile(field, colorName);
      } else {
        this.createTile(field);
      }
    });
  }

  createTile(field, colorName) {
    let tile;
    if (colorName) {
      tile = this.tileFactoryInstance.generateByColor(colorName);
    } else {
      tile = this.tileFactoryInstance.generateRandom();
    }

    field.setTile(tile);

    if (this.pixiUi) {
      this.container.addChild(tile.sprite);
      this.container.addChild(tile.animation);
      this.container.addChild(tile.bombAnimation);
      this.container.addChild(tile.swapAnimation);

      tile.sprite.interactive = true;

      tile.sprite
        .on('pointerdown', event => {
          this.isPointerUpTriggered = false;
          this.container.emit('tile-touch-start', { tile, event });
        })
        .on('pointerup', event => {
          this.isPointerUpTriggered = true;
          this.container.emit('tile-touch-end', { tile, event });
        })
        .on('pointerupoutside', event => {
          if (!this.isPointerUpTriggered) {
            this.container.emit('tile-touch-end', { tile, event });
          }
        })
        .on('pointermove', event => {
          this.container.emit('tile-touch-drag', { tile, event });
        })
        .on('touchstart', event => {
          this.isPointerUpTriggered = false;
          this.container.emit('tile-touch-start', { tile, event });
        })
        .on('touchend', event => {
          this.isPointerUpTriggered = true;
          this.container.emit('tile-touch-end', { tile, event });
        })
        .on('touchendoutside', event => {
          if (!this.isPointerUpTriggered) {
            this.container.emit('tile-touch-end', { tile, event });
          }
        });
    }

    return tile;
  }

  getField(row, col) {
    return this.fields.find(field => field.row === row && field.col === col);
  }

  createFields() {
    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.cols; col++) {
        this.createField(row, col);
      }
    }
  }

  createField(row, col) {
    const field = new Field(row, col, this.pixiUi);
    this.fields.push(field);
    if (this.pixiUi) {
      this.container.addChild(field.sprite);
    }
  }

  ajustPosition() {
    this.fieldSize = this.fields[0].sprite.width;
    this.width = this.cols * this.fieldSize;
    this.height = this.rows * this.fieldSize;
    this.container.x =
      (this.configInstace.uiConstants.containerX - this.width) / 2 + this.fieldSize / 2;
    this.container.y = this.fieldSize / 2;
  }

  swap(tile1, tile2) {
    const tile1Field = tile1.field;
    const tile2Field = tile2.field;

    tile1Field.tile = tile2;
    tile2.field = tile1Field;

    tile2Field.tile = tile1;
    tile1.field = tile2Field;
  }
}
