import { animationPaths, assetsPaths } from './assetsPath';
// eslint-disable-next-line no-unused-vars
import * as PIXI from 'pixi.js';

/**
 * @param {PIXI.AssetsClass} Assets
 * @returns {Promise<void>}
 */
export default function preload(Assets, callback = undefined) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const allPaths = [...assetsPaths, ...animationPaths];

    /* loading only unloaded assets */
    for (let i = 0; i < allPaths.length; i++) {
      const path = allPaths[i];
      if (!PIXI.Cache.get(path)) await Assets.load(path);
      if (callback) callback(((i + 1) / allPaths.length) * 50);
      // await new Promise((resolve) => setTimeout(resolve, 500));  // for debugging
    }

    resolve();
  });
}
