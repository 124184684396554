<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div className="container mt-2" style="height: 12%">
    <div className="h-100 row justify-content-between">
      <div className="h-100 col w-50">
        <div
          className="align-items-center d-flex h-100 justify-content-center powerup-container w-100"
        >
          <img
            @click="() => handleClick(playerPowerups[0])"
            :src="getPowerupUrlByName(playerPowerups[0]?.name, false)"
            :class="`h-50 w-auto m-1 button ${getStatusClassByStatus(
              playerPowerups[0]?.status,
            )}`"
          />
          <img
            @click="() => handleClick(playerPowerups[1])"
            :src="getPowerupUrlByName(playerPowerups[1]?.name, false)"
            :class="`h-50 w-auto m-1 button ${getStatusClassByStatus(
              playerPowerups[1]?.status,
            )}`"
          />
        </div>
      </div>
      <div className="h-100 col w-50">
        <div
          className="align-items-center d-flex h-100 justify-content-center powerup-container w-100"
        >
          <img
            :src="getPowerupUrlByName(opponentPowerups[0]?.name, true)"
            :class="`h-50 w-auto m-1 button ${getStatusClassByStatus(
              opponentPowerups[0]?.status,
            )}`"
          />
          <img
            :src="getPowerupUrlByName(opponentPowerups[1]?.name, true)"
            :class="`h-50 w-auto m-1 button ${getStatusClassByStatus(
              opponentPowerups[1]?.status,
            )}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { POWERUPS_STATUS, POWER_UPS } from '../../enum/enum';
  import { imageUrls } from '../../constants/imageUrlPath';

  export default {
    props: {
      playerPowerups: Array,
      opponentPowerups: Array,
    },
    methods: {
      getPowerupUrlByName(powerup, isOpponent = false) {
        if (!powerup) return;
        const imageUrlKey = isOpponent ? `${powerup}Red` : `${powerup}Blue`;
        return imageUrls[imageUrlKey] || '';
      },

      getStatusClassByStatus(status) {
        switch (status) {
          case POWERUPS_STATUS.USED:
            return 'disabledImg';

          case POWERUPS_STATUS.SELECTED:
            return 'afterSelection';

          default:
            return '';
        }
      },

      handleClick(powerup) {
        const isBurst4Selected = this.playerPowerups.find(
          pp => pp.status === POWERUPS_STATUS.SELECTED && pp.name == POWER_UPS.BURST_FOUR,
        );
        const isSwap2Selected = this.playerPowerups.find(
          pp => pp.status === POWERUPS_STATUS.SELECTED && pp.name == POWER_UPS.SWAP_TWO,
        );

        /* this two combinations are not allowed */
        /* if swap two selected then burst 4 is not allowed and vise-versa */
        if (
          (isBurst4Selected && powerup.name === POWER_UPS.SWAP_TWO) ||
          (isSwap2Selected && powerup.name === POWER_UPS.BURST_FOUR)
        ) {
          return;
        }

        // console.log({ isAnyPowerupSelected }, powerup);
        if (powerup.status === POWERUPS_STATUS.NOT_USED) {
          this.$emit('on-powerup-use', powerup.name);
        }
      },
    },
  };
</script>

<style>
  @import './index.css';
</style>
