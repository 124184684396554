/**
 * EventEmitter class.
 *
 * @class EventEmitter
 * @constructor
 */
export class EventEmitter {
  /**
   * Constructor.
   */
  constructor(pixiUi) {
    this.pixiUi = pixiUi;
    this.callbacks = {};
  }

  /**
   * @param {string} event - event name to be triggered
   * @param {Function} cb - callback function that is to be executed
   */
  on(event, cb) {
    if (!this.callbacks[event]) this.callbacks[event] = [];
    if (!this.pixiUi) {
      this.callbacks[event].pop();
    }
    this.callbacks[event].push(cb);
  }

  /**
   * Remove a specific callback function for the given event.
   * @param {string} event - The name of the event.
   * @param {Function} cb - The callback function to remove.
   */
  off(event, cb) {
    const callbacks = this.callbacks[event];
    if (callbacks) {
      const index = callbacks.indexOf(cb);
      if (index !== -1) {
        callbacks.splice(index, 1);
      }
    }
  }

  /**
   * Remove all listeners for a specific event.
   * @param {string} event - The name of the event to remove all listeners from.
   */
  removeAllListeners(event) {
    delete this.callbacks[event];
  }

  /**
   * Emit an event with optional data.
   * @param {string} event - The name of the event.
   * @param {*} data - Optional data to pass to the event listeners.
   */
  emit(event, data) {
    let cbs = this.callbacks[event];
    if (cbs) {
      cbs.forEach((cb) => cb(data));
    }
  }
}
